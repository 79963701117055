import "./App.css"
import { useState } from "react"
import {
    FaYoutube,
    FaDiscord,
    FaGithub,
    FaStore,
    FaExternalLinkAlt,
} from "react-icons/fa"
import { SiFivem } from "react-icons/si"
import { motion } from "framer-motion"
import Spline from "@splinetool/react-spline"

function App() {
    const [splineLoaded, setSplineLoaded] = useState(false)

    return (
        <div className="mainPage">
            <motion.div
                className="needHelp"
                initial={{ opacity: 0, scale: 0.0 }}
                animate={{ opacity: 1, scale: 1.0 }}
                transition={{ duration: 1.0, delay: 5.0 }}
            >
                <div className="needHelpText">Need help?</div>
                <a
                    className="needHelpButton"
                    href="https://discord.com/invite/EgzT2fwsAA"
                    target="_blank"
                >
                    Join our Discord
                    <FaExternalLinkAlt />
                </a>
            </motion.div>
            <div className="header">
                {/* ELECTUS */}

                <a
                    href="https://forum.cfx.re/u/krille/summary"
                    target="_blank"
                    className="fivemIcon"
                >
                    <SiFivem />
                </a>
                <a
                    href="https://discord.com/invite/EgzT2fwsAA"
                    target="_blank"
                    className="discordIcon"
                >
                    <FaDiscord />
                </a>
                <a
                    href="https://github.com/KRILLE123"
                    target="_blank"
                    className="githubIcon"
                >
                    <FaGithub />
                </a>
                <a
                    href="https://www.youtube.com/@electusscripts"
                    target="_blank"
                    className="youtubeIcon"
                >
                    <FaYoutube />
                </a>
                <a
                    href="https://store.electus-scripts.com/"
                    target="_blank"
                    className="storeIcon"
                >
                    <FaStore />
                </a>
            </div>
            <div className="content">
                {/* {!splineLoaded && <div className="loading">Loading...</div>} */}
                <div className="orbit">
                    <motion.div
                        className="orbiting"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 2.0, delay: 1.5 }}
                    >
                        <Spline
                            onLoad={() => setSplineLoaded(true)}
                            style={{ width: "25vw", aspectRatio: 1 }}
                            scene="https://prod.spline.design/4zFGyw29jWOyBRgp/scene.splinecode"
                        />
                    </motion.div>

                    <motion.div
                        className="title"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.0, delay: 1.5 }}
                    >
                        Electus
                        <motion.div
                            className="subTitle"
                            initial={{ x: -10, opacity: 0 }}
                            animate={{ opacity: 1, scale: 1, x: 0 }}
                            transition={{ duration: 1.0, delay: 2.5 }}
                        >
                            Scripts
                        </motion.div>
                    </motion.div>
                </div>
                <motion.div
                    className="about"
                    initial={{ x: -70, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1.0, delay: 0.25 }}
                >
                    About
                    <p>
                        Electus Script, formerly known as KRILLE Development,
                        has been in operation since 2019. It's a one-person
                        endeavor led by myself, Krille. My passion for
                        programming took root in FiveM development back in 2017
                        and has since blossomed into my current pursuit of
                        Computer Science studies. The nostalgic connection to
                        FiveM remains close to my heart, and I'm dedicated to
                        ongoing script development as long as there are people
                        interested in utilizing my work.
                    </p>
                </motion.div>
                <motion.div
                    className="about"
                    initial={{ x: -70, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1.0, delay: 0.25 }}
                >
                    Buyers Protection
                    <p>
                        Electus Scripts stands behind every product sold,
                        ensuring your satisfaction. We provide full refunds if,
                        despite our offered support on Discord, the script
                        doesn't function as expected.
                    </p>
                </motion.div>
                <motion.div
                    className="about "
                    initial={{ x: -70, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1.0, delay: 0.25 }}
                >
                    Script Showcase
                    <div className="videos">
                        <iframe
                            className="video"
                            src="https://www.youtube.com/embed/l6mu29X_HzM?si=9x2F-geqUZJWNUTW"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share"
                            allowfullscreen
                        ></iframe>
                        <iframe
                            className="video"
                            src="https://www.youtube.com/embed/W4fE_jpgYyo?si=m9XlZ6hnNgPD9Fnt"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share"
                            allowfullscreen
                        ></iframe>
                        <iframe
                            className="video"
                            src="https://www.youtube.com/embed/WbuYYWI8dF4?si=f3eJIH83-E56Bn-m"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share"
                            allowfullscreen
                        ></iframe>
                        <iframe
                            className="video"
                            src="https://www.youtube.com/embed/_fMoN1v-tGg?si=cQyl4e0YZvkxMT9N"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share"
                            allowfullscreen
                        ></iframe>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default App
